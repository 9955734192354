<template>
  <div class="px-2 py-5">
    <h1 class="title">Prueba de Urticaria Activity Score</h1>
    <b-message type="is-info">
      Por favor, marque con un círculo su respuesta para cada una de las siete
      preguntas. Si su hijo es suficientemente mayor para entender las
      preguntas, rellenen el cuestionario juntos. Por favor, deje en blanco
      cualquier pregunta que no sea capaz de responder.
    </b-message>

    <div class="block">
      <span>
        1. ¿Cuánto ha sufrido en las últimas 4 semanas los sintomas fisicos de
        la urticaria(Picazón,ronchas,y/o hinchazón)?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.UCT_P1"
            :native-value="answers.UCT_P1.UCT_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.UCT_P1"
            :native-value="answers.UCT_P1.UCT_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.UCT_P1"
            :native-value="answers.UCT_P1.UCT_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Regular
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.UCT_P1"
            :native-value="answers.UCT_P1.UCT_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Poco
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.UCT_P1"
            :native-value="answers.UCT_P1.UCT_P1_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. ¿Se ha visto afectada su calidad de vida en las últimas 4 semanas
        debido a la urticaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.UCT_P2"
            :native-value="answers.UCT_P2.UCT_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.UCT_P2"
            :native-value="answers.UCT_P2.UCT_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.UCT_P2"
            :native-value="answers.UCT_P2.UCT_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Regular
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.UCT_P2"
            :native-value="answers.UCT_P2.UCT_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Poco
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.UCT_P2"
            :native-value="answers.UCT_P2.UCT_P2_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. ¿Con qué frecuencia en las últimas 4 semanas el tratamiento médico no
        ha sido suficiente para controlar los síntomas de la urticaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.UCT_P3"
            :native-value="answers.UCT_P3.UCT_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.UCT_P3"
            :native-value="answers.UCT_P3.UCT_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.UCT_P3"
            :native-value="answers.UCT_P3.UCT_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Regular
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.UCT_P3"
            :native-value="answers.UCT_P3.UCT_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Poco
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.UCT_P3"
            :native-value="answers.UCT_P3.UCT_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        4. Globalmente, indique qué tan bien controlada ha tenido su urticaria
        en las últimas 4 semanas
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.UCT_P4"
            :native-value="answers.UCT_P4.UCT_P4_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.UCT_P4"
            :native-value="answers.UCT_P4.UCT_P4_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.UCT_P4"
            :native-value="answers.UCT_P4.UCT_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Regular
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.UCT_P4"
            :native-value="answers.UCT_P4.UCT_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.UCT_P4"
            :native-value="answers.UCT_P4.UCT_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
      </div>
    </div>

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      {{ noControled }}
      <br />
      Buena respuesta >= 12
      <br />
      Respuesta completa 16
    </b-message>

    <div class="block has-text-centered title">
      El resultado es: {{ score }}
      <span>({{ scoreText }})</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="save"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { UCT as questions } from "../../../data/questions";
import { UCT as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      answerFour: "",
      noControled: "No controlado < 12",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "UctList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) +
          parseInt(this.answerTwo || 0) +
          parseInt(this.answerThree || 0) +
          parseInt(this.answerFour || 0) || 0
      );
    },
    isIncompleted() {
      return (
        this.answerOne === "" ||
        this.answerTwo === "" ||
        this.answerThree === "" ||
        this.answerFour === ""
      );
    },
    scoreText() {
      let text = "";

      if (parseInt(this.score) >= 0 && this.score <= 11) {
        text = "No controlado";
      }

      if (this.score >= 12 && this.score <= 15) {
        text = "Buena respuesta";
      }

      if (this.score == 16) {
        text = "Respuesta completa";
      }

      return text;
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
      this.answerFour = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;

      
      this.restoreForm();
      this.clearTemporalSurveys();
      this.initialAnswer();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta UCT.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "UCT",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;

      Object.keys(this.answers[questions.UCT_P1]).forEach((key) => {
        if (this.answers[questions.UCT_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.UCT_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.UCT_P2]).forEach((key) => {
        if (this.answers[questions.UCT_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.UCT_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.UCT_P3]).forEach((key) => {
        if (this.answers[questions.UCT_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.UCT_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.UCT_P4]).forEach((key) => {
        if (this.answers[questions.UCT_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.UCT_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      return [question1, question2, question3, question4];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "UCT",
          route: "Uct",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "UCT_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "UCT_P2") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "UCT_P3") {
          this.answerThree = survey.value;
        }

        if (survey.question.code == "UCT_P4") {
          this.answerFour = survey.value;
        }
      });
    },
    initialAnswer(){
      this.answerOne = this.answers.UCT_P1.UCT_P1_R5;
      this.answerTwo = this.answers.UCT_P2.UCT_P2_R5;
      this.answerThree = this.answers.UCT_P3.UCT_P3_R5;
      this.answerFour = this.answers.UCT_P4.UCT_P4_R1;
    }
  },
};
</script>
